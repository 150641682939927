import { Div } from '~/components/html'
import { css, position, size } from '~/libs'

type Props = ComponentProps<'div' | 'header' | 'section'>

export function VerticalSliderSlide(props: Props) {
  const styled = {
    root: css({
      ...position({ position: 'relative' }),
      ...size({ minHeight: 'fit-content !important', width: '100%' })
    })
  }
  return <Div class={`keen-slider__slide ${styled.root} ${props.class ?? ''}`}>{props.children}</Div>
}
