import KeenSlider from 'keen-slider'
import 'keen-slider/keen-slider.min.css'
import type { KeenSliderInstance } from 'keen-slider'

export { KeenSlider }

type Position = { x: number, y: number }
type WheelEventExtended = WheelEvent & { pageX: number, pageY: number }

export class WheelControls {
  private touchTimeout?: ReturnType<typeof setTimeout>
  private position: Position = { x: 0, y: 0 }
  private wheelActive = false
  private slider: KeenSliderInstance
  constructor(slider: KeenSliderInstance) {
    this.slider = slider
    this.init()
  }
  private dispatch(e: WheelEventExtended, name: string) {
    this.position.x -= e.deltaX
    this.position.y -= e.deltaY
    this.slider.container.dispatchEvent(
      new CustomEvent(name, {
        detail: {
          x: this.position.x,
          y: this.position.y
        }
      })
    )
  }
  private wheelStart(e: WheelEventExtended) {
    this.position = {
      x: e.pageX,
      y: e.pageY
    }
    this.dispatch(e, 'ksDragStart')
  }
  private wheelMove(e: WheelEventExtended) {
    this.dispatch(e, 'ksDrag')
  }
  private wheelEnd(e: WheelEventExtended) {
    this.dispatch(e, 'ksDragEnd')
  }
  private eventWheel(e: WheelEventExtended) {
    e.preventDefault()
    if (!this.wheelActive) {
      this.slider.options.rubberband = false
      this.wheelStart(e)
      this.wheelActive = true
    }
    this.wheelMove(e)
    if (this.touchTimeout) clearTimeout(this.touchTimeout)
    this.touchTimeout = setTimeout(() => {
      this.slider.options.rubberband = true
      this.wheelActive = false
      this.wheelEnd(e)
    }, 50)
  }
  private init() {
    this.slider.on('created', () => {
      this.slider.container.addEventListener('wheel', this.eventWheel.bind(this) as EventListener, {
        passive: false
      })
    })
  }
}
