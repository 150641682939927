import { splitProps } from 'solid-js'
import { Div } from '~/components/html'
import { useVirtualContext } from '~/contexts'
import { KeenSlider, WheelControls, css, layout, size, ux } from '~/libs'

type Props = ComponentProps<'div' | 'header' | 'section' | 'article'> & { space?: number }
type PresenterProps = Props & { mount: (ref: HTMLElement) => Promise<void> }

export function VerticalSlider(props: Props) {
  const ctx = useVirtualContext()
  if (!ctx) throw new Error('ctx is not defined')
  const { VITE_VIRTUAL_LAYOUT_HEIGHT } = import.meta.env
  const mount = async (ref: HTMLElement) => {
    await new Promise(resolve => setTimeout(() => resolve(true), 0)) // await 1 tick
    ref.style.height = `calc(100% - ${props.space ?? 0}px)` // hack
    const slider = new KeenSlider(ref, { mode: 'free', rubberband: true, slides: { perView: 'auto' }, vertical: true })
    new WheelControls(slider)
    const height = ctx.isMobile()
      ? window.innerHeight - (props.space ?? 0)
      : Number(VITE_VIRTUAL_LAYOUT_HEIGHT.replace('px', '')) - (props.space ?? 0)
    // console.log('🔥', 'VerticalSlider', { height })
    slider.on('detailsChanged', e => {
      const h = ctx.scrollHeight() - height + 1 // 1 is spacer last-child
      const y = h * e.track.details.progress
      ctx.setScrollTop(y)
    })
    slider.on('created', () => {
      ref.style.flexFlow = 'wrap' // hack
    })
    slider.emit('created')
    slider.on('updated', () => ctx.update(slider))
    ctx.setSlider(slider)
  }
  return <Presenter {...props} mount={mount} />
}

function Presenter(props: PresenterProps) {
  const [{ mount }] = splitProps(props, ['mount'])
  const styled = {
    root: css({
      ...layout({ flexFlow: 'column' }), // hack
      ...size({ height: 'fit-content' }), // hack
      ...ux({ cursor: 'grab' }),
      '&:active': {
        ...ux({ cursor: 'grabbing' })
      }
    })
  }
  return (
    <Div
      class={`keen-slider ${styled.root} ${props.class ?? ''}`}
      ref={mount}
    >
      {props.children}
    </Div>
  )
}
